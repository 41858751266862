import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyList from '../../containers/PropertyListContainer'
import './style.scss'

export default function () {
	const [ toggle, toggleMenu ] = useState(false)

	return (
		<React.Fragment>
			<li><a href='/' onClick={() => window.scrollTo(0, 0)}>Home</a></li>
			<li><a href='/about-us' onClick={() => window.scrollTo(0, 0)}>About</a></li>
			<li className='has-mega-menu'>
				<a href='/projects'>Projects</a>
				<div className='mega-menu'>
					<div className='mega-menu-body'>
						<PropertyList featured menu />
					</div>
				</div>
			</li>
			<li><a href='/contact-us' onClick={() => window.scrollTo(0, 0)}>Contact</a></li>
		</React.Fragment>
	)
}