import React, { Component } from 'react';
import Slider from "react-slick";
import { endpoints } from '../../../config'
import './style.scss';

export default class SecondaryBanner extends Component {

  render() {
    const { bannerImages, propertyName, rera } = this.props;
    
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 3000,
      autoplaySpeed: 3000,
      variableWidth: true
    };
    
    const images = bannerImages ? bannerImages.split(',').map((img, key) => (
      <div key={key}>
        <img src={`${endpoints.apiBaseURL}${img.replace('./', '')}`} />
      </div>
    )) : null

    return (
      <section className="slider-banner">
        <Slider {...settings}>
          {images }
        </Slider>
        { propertyName && (
          <div className='container intro'>
            <div>
              <h1>{propertyName}</h1>
              {
                rera && (
                  <div className="rera-number-wrapper">
                    <span className="rera-number">Rera Number : {rera}</span>
                  </div>
                )
              }
            </div>
          </div>
        )}
      </section>
    );
  }
}