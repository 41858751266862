import React from 'react';
import './style.scss';

export default function(props) {
  const { handler, floor } = props;
  return (
    <div className="col-xs-12 col-md-6 col-lg-4">
      <div className='floor-node'>
        <div className="row align-items-center">
          <div className="col-md-7">
            { handler }
          </div>
          <div className="col-md-5">
            <div className="quick-details">
              <ul className="list">
                <li className="item bhk">{floor.key.replace('_', ' ')}</li>
                <li className="item sq-ft"><span>SQ. FT.</span> {floor.sqrtFeet}</li>
                <li className="item sq-ft"><span>Available</span> {floor.totalAvailable}</li>
                <li className="item price"><span>Price</span>₹ {floor.price}/-</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
