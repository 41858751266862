import React, { Component } from 'react';
import './style.scss';

class BriefAboutUs extends Component {
  render() {
    return (
      <section className="about-section">
        <div className="about-us container">
          <div className="row align-items-center">
            <div className="col-md-3 col-12 section-title">
              <h2>About <span className="font-theme-color">Us</span></h2>
            </div>
            <div className="col-md-9 col-12 si-about">
            Vajra Realties is a Builders &amp; Developers firm in Mangalore. We also undertake projects from other developers for sales with competitive price which will help customers to buy property within their reach.
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default BriefAboutUs;