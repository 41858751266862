import React from 'react'
import { notify } from 'react-notify-toast';
import './style.scss'

// type
// body

export default class GeneralForm extends React.Component {

  state = {
    name: '',
    email: '',
    message: '',
    phone: ''
  }

  handleSubmit = (e) => {

    e.preventDefault()
    const { name, email, phone, message } = this.state
    const { type, submitForm, handleClose } = this.props

    if (!name) {
      return notify.show('Please enter name', 'error', 3000)
    }
    if (!email) {
      return notify.show('Please enter email', 'error', 3000)
    }
    if (!phone) {
      return notify.show('Please enter phone', 'error', 3000)
    }
    if (!message) {
      return notify.show('Please enter message', 'error', 3000)
    }

    const fd = new FormData()
    fd.set('name', name)
    fd.set('email', email)
    fd.set('phone', phone)
    fd.set('enqType', type)
    fd.set('desc', JSON.stringify(this.transformBody()))

    submitForm(fd).then(() => {
      notify.show('Your message has been sent', 'success', 3000)
      handleClose && handleClose()
      this.setState({
        name: '',
        email: '',
        message: '',
        phone: ''
      })
    }).catch(() => {
      handleClose && handleClose()
      notify.show('Sorry, there was internal error', 'error', 3000)
    })

  }

  transformBody = () => {
    const { body, type } = this.props
    const { message } = this.state
    return type === 'CONTACT' ? message : { ...body, message }
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value})
  }

  render() {
    const { name, email, phone, message } = this.state
    return (
      <div className="general-form-field">
        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="single-input-field">
                <input value={name} onChange={this.handleChange} name='name' placeholder="First Name" type="text"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="single-input-field">
                <input value={email} onChange={this.handleChange} name='email' placeholder="E-mail" type="email"/>
              </div>
            </div>
            <div className="col-12">
              <div className="single-input-field">
                <input value={phone} onChange={this.handleChange} name='phone' placeholder="Phone number" type="number"/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="single-input-field">
                <textarea placeholder="Message" value={message} onChange={this.handleChange} name='message'></textarea>
              </div>
            </div>
          </div>
          <div className="sb-button">
            <input value="Send Message" className="theme-transition theme-btn" type="submit"/>
          </div>
        </form>
      </div>
    )
  }
}