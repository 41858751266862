import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import SecondaryBanner from '../../components/SecondaryBanner';
import AboutUs from '../../components/AboutUs';
import OurTeam from '../../components/OurTeam';
import Testimonials from '../../components/Testimonials';

import aboutBanner from '../../assets/images/about_page_banner.jpg';

class AboutUsPage extends Component {
  render() {
    const meta = {
      title: 'About Vajra Realties Mangalore',
      description: '',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'vajra realties, properties in mangalore, prop links mangalore'
        }
      }
    }
    return (
      <DocumentMeta {...meta}>
        <SecondaryBanner bannerImage={aboutBanner}/>
        <AboutUs />
        {/* <OurTeam /> */}
        <Testimonials />
      </DocumentMeta>
    );
  }
}

export default AboutUsPage;