import React, { Component } from 'react';
import ProjectNode from '../ProjectNode';
import Loading from '../Loading';

import './style.scss';

class PropertyList extends Component {

  state = {
    selected: 'ALL'
  }

  componentDidMount() {
    this.props.getProperties(this.props.featured)
  }

  render() {
    const { propertyList, featured, menu } = this.props
    const { selected } = this.state

    if (!propertyList) {
      return <Loading />
    }

    return (
      <section className={`property-list-container ${menu ? 'mega-menu-content' : ''}`}>
        <div className="portfolio-gallery">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                {
                  !menu && (
                    featured
                      ? <h2>In The <span className="font-theme-color">Spotlight</span></h2>
                      : <h2>Top <span className="font-theme-color">Properties</span></h2>
                  )
                }
                </div>
              </div>
            </div>

            <div className="row">
              <div className={`col-12 ${menu ? 'd-flex' : ''}`}>
                <div className='project-status-btn'>
                  <ul>
                    <li><button onClick={() => this.setState({selected: 'ALL'})} className={ selected === 'ALL' ? 'active' : '' }>All Projects</button></li>
                    <li><button onClick={() => this.setState({selected: 'ONGOING'})} className={ selected === 'ONGOING' ? 'active' : '' }>Ongoing Projects</button></li>
                    <li><button onClick={() => this.setState({selected: 'UPCOMING'})} className={ selected === 'UPCOMING' ? 'active' : '' }>Upcoming Projects</button></li>
                    <li><button onClick={() => this.setState({selected: 'COMPLETED'})} className={ selected === 'COMPLETED' ? 'active' : '' }>Completed Projects</button></li>
                  </ul>
                </div>
                <div className="row portfolio">
                  {
                    propertyList && propertyList.map((data, key) => {
                      return selected === 'ALL' ||
                      (selected === 'ONGOING' && data.projectStatus === 'On-going') ? (
                        <ProjectNode
                          menu={menu}
                          key={key}
                          title={data.title}
                          address={data.completeAdress}
                          builders={data.builder}
                          flooring={data.addedFloorInfo}
                          coverImg={data.coverImage}
                          link={data.uri}
                        />
                      )

                      : selected === 'ALL' || (selected === 'UPCOMING' && data.projectStatus === 'Forthcoming') ? (
                        <ProjectNode
                          menu={menu}
                          key={key}
                          title={data.title}
                          address={data.completeAdress}
                          builders={data.builder}
                          flooring={data.addedFloorInfo}
                          coverImg={data.coverImage}
                          link={data.uri}
                        />
                      )

                      : selected === 'ALL' || (selected === 'COMPLETED' && data.projectStatus === 'Completed') ? (
                        <ProjectNode
                          menu={menu}
                          key={key}
                          title={data.title}
                          address={data.completeAdress}
                          builders={data.builder}
                          flooring={data.addedFloorInfo}
                          coverImg={data.coverImage}
                          link={data.uri}
                        />
                      )

                      : false
                    })
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-cm"/>
      </section>
    );
  }
}

export default PropertyList;