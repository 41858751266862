import React, { Component } from 'react';
import Overview from './Overview';
import Amenities from './Amenities';
import Location from './Location';
import WalkThrough from './WalkThrough';
import FloorPlan from './FloorPlan';
import NearByPlaces from './NearByPlaces';
import SecondaryBanner from './Slider';
import Loading from '../Loading';
import DocumentMeta from 'react-document-meta';
import Specifications from './Specification'


import './style.scss';

class PropertyDetails extends Component {

  state = {
    show: false
  }

  componentDidMount = () => {
      this.props.getPropertyDetails(this.props.match.params.uri);
    window.addEventListener('scroll', () => {
      if(window.scrollY > 400 && this.state.show === false){
        this.setState({
          show: 'active'
        })
      }
      else if(this.state.show === 'active' && window.scrollY <= 400) {
        this.setState({
          show: false
        })
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => {}, false)
  }


  componentDidUpdate(props) {
    if(this.props.match.params.uri !== props.match.params.uri) {
      this.props.getPropertyDetails(this.props.match.params.uri); 
    }

  }


  render() {

    if(!this.props.propertyDetails) {
      return <Loading />
    }

    const {
      title,
      coverImage,
      reraNo,
      aboutProject,
      completeAdress,
      addedFloorInfo,
      amenitiesAdded,
      iframeDataLocation,
      galleryImgPaths,
      iframeYoutubeLink,
      brochureLocation,
      addedNearByPlaces,
      builder,
      bankSupported,
      projectStatus,
      projectType,
      description,
      keywords,
      specification,
    } = this.props.propertyDetails ? this.props.propertyDetails[0] : [];

    const meta = {
      title: title,
      description: description,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: keywords
        }
      }
    }

    return (
      <DocumentMeta {...meta}>
        <SecondaryBanner
          propertyName={title}
          bannerImages={galleryImgPaths}
          rera={reraNo}
        />

        <section className="property-details-section">

          <ul className={this.state.show ? 'sticky-nav active' : 'sticky-nav'} id='sticky-nav'>
            <li><a href="#overview">Overview</a></li>
            <li><a href="#amenities">Amenities</a></li>
            <li><a href="#specifications">Specifications</a></li>
            <li><a href="#floor-plan">Floor Plan</a></li>
            <li><a href="#gallery">Gallery</a></li>
            <li><a href="#location">Location</a></li>
          </ul>

          <Overview propertyName={title} aboutProject={aboutProject} completeAdress={completeAdress} banks={bankSupported} builderName={builder} brochure={brochureLocation} projectStatus={projectStatus} projectType={projectType} />
          <Amenities amenities={amenitiesAdded} />
          <Specifications specifications={specification}/>
          <FloorPlan flooring={addedFloorInfo} />
          <NearByPlaces nearByPlaces={addedNearByPlaces} />
          <WalkThrough galleryImages={galleryImgPaths} iframe={iframeYoutubeLink} />
          <Location iframe={iframeDataLocation} completeAdress={completeAdress} builderName={builder} />
        </section>
      </DocumentMeta>
    );
  }
}

export default PropertyDetails;