import React, { Component } from 'react';
import { endpoints } from '../../../config';
import { RViewer, RViewerTrigger } from 'react-viewerjs'
import './style.scss'


class WalkThrough extends Component {
  render() {
    const { galleryImages, iframe } = this.props
    const images = galleryImages ? galleryImages.split(',').map(img => `${endpoints.apiBaseURL}${img.replace('./', '')}`) : []

    return (
      <div className='walk-through-container container' id='gallery'>
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2><span className="font-theme-color">Gallery</span></h2>
            </div>
          </div>
        </div>
        <RViewer imageUrls={images}>
          <div className='image-gallery'>
            {images.map((pic, index) => {
              return (
                <RViewerTrigger index={index} key={index}>
                  <img src={pic} style={{width:"50px",verticalAlign:"middle"}}  />
                </RViewerTrigger>
              )
            })
            }
          </div>
        </RViewer>
        {/* <Gallery photos={images} /> */}
        { iframe && <div className='youtube-link' dangerouslySetInnerHTML={{__html: iframe}}></div> }
      </div>
    )
  }
}

export default WalkThrough;
