import React, { Component } from 'react';
import EnquiryForm from '../EnquiryForm';
import { endpoints } from '../../../config'
import './style.scss';

class Overview extends Component {
  render() {
    const { propertyName, aboutProject, completeAdress, brochure, builderName, banks, projectStatus, projectType } = this.props;
    return (
      <div className="overview container" id='overview'>
        <div className="row">
          <div className="col-md-12 text-center d-flex align-items-center">
            <div className="section-title">
              <h2>Project <span className="font-theme-color">Highlight</span></h2>
            </div>
            <div className="description">
              <div className="text" dangerouslySetInnerHTML={{__html: aboutProject}} />
              <br />
              <p className='text-left-sm'>Project Type: <strong>{projectType}</strong></p>
              <p className='text-left-sm'>Project Status: <strong>{projectStatus}</strong></p>

              <div className="builder">
                <h4>Builder:</h4>
                <p>{builderName}</p>
                <p>{completeAdress}</p>
              </div>

              <div className="bank-node-wrapper">
                { banks && banks.length ? <h4>Approved Banks:</h4> : null }
                <div className='wrapper'>
                  {
                    banks && banks.map(({bankName, bankLogoPath }) => (
                      <div key={bankName} className='node'>
                        <img alt={''} src={`${endpoints.apiBaseURL}${bankLogoPath}`} />
                        <span>{bankName}</span>
                      </div>
                    ))
                  }
                </div>
              </div>

              <a className="btn-download theme-transition" href={`${endpoints.apiBaseURL}${brochure.replace('./', '')}`} target='_blank' rel='noreferrer'>
                <i className="fa fa-download"></i> Download Brochure
              </a>

              <EnquiryForm propertyName={propertyName} url={window.location.href} />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Overview;