import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/GeneralForm';
import { submitForm } from '../ducks/PropertyDuck';

const mapStateToProps = (state) => ({
  propertyDetails: state.propertyDuck.propertyDetails
});

const mapDispatchToProps = (dispatch) => ({
  submitForm: formData => dispatch(submitForm(formData))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component));