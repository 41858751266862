import React, { Component } from 'react';
import Slider from "react-slick";
import messages from './messages';
import './style.scss';

class Testimonials extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrow: false,
            centerPadding: 0,
          }
        }
      ]
    };
    return (
      <section className="testimonials-section">
        <div className="testimonials-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title">
                  <h2>What Customers Say</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="wrap-item-testimonial">
                  <Slider {...settings}>

                    {
                      messages && messages.map(({ photo, message, name, company }, key) => {
                        return (
                        <div className="item" key={key} >
                          <div className="item-testimonial">
                            <div className="client-info">
                              <div className="client-img">
                                <img src={photo} alt="profile"/>
                              </div>
                              <div className="rating">
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                                <span className="fa fa-star"></span>
                              </div>
                            </div>
                            <div className="client-content">
                              <p>{message}</p>
                              <div className="client-title">
                                <h4>{name}</h4>
                                <h5>{company}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        )
                      })
                    }
                    
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-tst">
          <div className="bg-color-tst"/>
          <div className="bg-img-tst"/>
        </div>
      </section>
    );
  }
}

export default Testimonials;