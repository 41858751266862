import React, { Component } from 'react';
import PropertyDetails from '../../containers/PropertyDetailContainer';

class PropertyDeatialsPage extends Component {
  componentDidMount () {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <PropertyDetails />
    );
  }
}

export default PropertyDeatialsPage;