import React, { Component } from 'react';
import PrimaryBanner from '../../components/PrimaryBanner';
import BriefAboutUs from '../../components/BriefAboutUs';
import Services from '../../components/Services';
import PropertyListContainer from '../../containers/PropertyListContainer';
import Testimonials from '../../components/Testimonials';
import DocumentMeta from 'react-document-meta';

class Home extends Component {
  render() {
    const meta = {
      title: 'Vajra Realties Mangalore',
      description: '',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'vajra realties, properties in mangalore, prop links mangalore'
        }
      }
    }
    return (
      <DocumentMeta {...meta}>
        <PrimaryBanner />
        <PropertyListContainer featured />
        <Services />
        <BriefAboutUs />
        <Testimonials />
      </DocumentMeta>
    );
  }
}

export default Home;