import React from 'react';
import { Link } from 'react-router-dom';
import { endpoints } from '../../config'
import './style.scss';

export default function(props) {
  const { coverImg, link, title, address, builders, flooring, menu } = props;
  let flooringData = JSON.parse(JSON.stringify(flooring))
  return (
    <div className={`node-wrapper ${menu ? 'in-menu' : ''}`}>
      <a className="project-node" href={`/property/${link}`}>
        <img src={`${endpoints.apiBaseURL}${coverImg.replace('./', '')}`} alt="gallery"/>
        <div className="quick-details">
          <div className='details-header'>
            <h2>{title}</h2>
            <p>{address}</p>
            <p>Built by: {builders}</p>
          </div>
          <div className='sizes-wrapper'>
          {
            flooringData ? flooringData.length ? (
              <React.Fragment>
                <h2>Flooring</h2>
                <ul>
                  { 
                    flooringData.splice(0,3).map((floor, key) => {
                      return (
                        <li key={key}>
                          <span>{floor.key.replace('_', ' ')}</span>
                          { floor.sqrtFeet && <span>{floor.sqrtFeet + ' sq. ft.'}</span>}
                          </li>
                      )
                    })
                  }
                </ul>
              </React.Fragment>
            ) : null : null
          }
          </div>
        </div>
      </a>
    </div>
  );
}
