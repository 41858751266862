import React, { Component } from 'react'
import './style.scss'
import { Row, Col, Nav, Tab } from 'react-bootstrap'

export default class Specification extends Component {
  render() {
    const { specifications } = this.props

    const pills = {
      left: [],
      right: []
    }
    const pane  = {
      left: [],
      right: []
    }
    
    const specLenDevider = specifications && specifications.length ? specifications.length / 2 : 1
    specifications && specifications.forEach((d, key) => {
      const attr = specLenDevider > key ? 'left' : 'right'
      const pillData = (
        <Nav.Item key={key}>
          <Nav.Link eventKey={key}>
            <span className={d.key.replace(/\s/g, '-').toLowerCase()}></span>
            {d.key}
          </Nav.Link>
        </Nav.Item>
      )
      const paneData = (
        <Tab.Pane eventKey={key} key={key}>
          <div className='content-wrapper' dangerouslySetInnerHTML={{__html: d.desc}} />
        </Tab.Pane>
      )
      pills[attr] = [...pills[attr], pillData]
      pane[attr] = [...pane[attr], paneData]
    })
    
    return (
      <React.Fragment>
      <div id='specifications'></div>
        <div className='container Specifications'>
          <div className="section-title">
            <h2 className='font-theme-color'>Specifications</h2>
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="0">
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-row">
                  {pills.left}
                  {pills.right}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  {pane.left}
                  {pane.right}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </React.Fragment>
    )
  }
}
