import React, { Component } from 'react';
import Logo from '../Logo'
import NavLink from '../NavLinks'
import './style.scss';

class Header extends Component {
  state = {
    open: false
  }
  render() {
    return (
      <nav className="header">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-auto">
              <Logo />
            </div>
            <div className="col-auto" style={{position: 'static'}}>
              <div className='on-desktop'>
                <ul className='nav-links'>
                  <NavLink />
                </ul>
              </div>
              <div className='on-mobile'>
                <button className='hamburger' onClick={() => this.setState(({open}) => ({open: !open})) }>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <ul className={`nav-links ${this.state.open ? 'open' : ''}`}>
                  <NavLink />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}


export default Header;