import defaultImg from './image_not_available.jpg';
export default [
  {
    photo: defaultImg,
    message: 'I want to thank you for all your proactive efforts done to provide sufficient information to help me buy my home in Mangalore. With just a few days I had to come down to India, your team arranged site tours which were very well organised and convenient for me and helped decision making an easy task',
    name: 'Suresh Nair',
    company: 'Dubai-Sharjah'
  },
  {
    photo: defaultImg,
    message: 'We would like to thank you all at the Vajra Realties office for your hard work. Your initial visit, with discussion over pricing and presentation, was enormously helpful: forward-looking and making positive suggestions. The presentation of the details, with superb photography, was very impressive. Your office is always welcoming yet professional. We are very pleased that we chose you to market our house',
    name: 'Praveen Kumar',
    company: 'General Manager-IBM-Bangalore'
  },
  {
    photo: defaultImg,
    message: 'I found the team at Vajra Realties  very professional and straightforward to deal with from start to finish. They were a pleasure to deal with and at all times were both quick to respond and pragmatic in their response.',
    name: 'John Fernandes ',
    company: 'USA'
  },
  {
    photo: defaultImg,
    message: 'I would like to commend Vajra Realties for their professionalism, always helpful nature and sincerity to help me buy my dream home. They followed up potential prospects in a timely manner and was always there week in and week out during our site tours. Their local knowledge and sage counsel gave us a relatively quick and painless result. I could not hesitate in recommending Vajra Realties and would be happy to use their services again.',
    name: 'Yajnesh Rao',
    company: 'Brigade Pinnacle-Mangalore (Sales Head)'
  },
  {
    photo: defaultImg,
    message: 'I personally thank Vajra Realties team for your honesty and integrity behind your advice, especially regarding valuation. The suggestions and informative updates you provided during our inspections and shortlisting the projects was really very helpful. Thank you for your efforts and excellent support during the finalisation related to finances and documentation.',
    name: 'Darshan Kumar',
    company: 'Provident Skyworth, Mangalore'
  },
  {
    photo: defaultImg,
    message: 'Thank you so much for the great service you provided us throughout the settlement process. We would definitely recommend you to our friends. Thank you once again Vajra Realties team. Just keep up the good work!',
    name: 'Sonal Suvarna',
    company: 'Sales Head-Transit One, Mangalore'
  },
  {
    photo: defaultImg,
    message: 'After searching for several weeks, Vajra Realties team helped me find the perfect home. Their service is fantastic and eliminated all the hassle. Needless to say, the entire experience exceeded my expectations.',
    name: 'Ramesh Shetty',
    company: 'Mumbai'
  },
]