import React, { Component } from 'react';
import SecondaryBanner from '../../components/SecondaryBanner';
import PropertyList from '../../containers/PropertyListContainer';
import DocumentMeta from 'react-document-meta';
import propertyBanner from '../../assets/images/property_page_banner.jpg';

class PropertyPage extends Component {
  render() {
    const meta = {
      title: 'Listed Properties : Vajra Realties Mangalore',
      description: '',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'vajra realties, properties in mangalore, prop links mangalore'
        }
      }
    }
    return (
      <DocumentMeta {...meta}>
        <SecondaryBanner bannerImage={propertyBanner}/>
        <PropertyList />
      </DocumentMeta>
    );
  }
}

export default PropertyPage;