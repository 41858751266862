import React, { Component } from 'react';
import sliderImage1 from '../../assets/images/home-banner/1.jpeg';
import sliderImage2 from '../../assets/images/home-banner/2.jpeg';
import sliderImage3 from '../../assets/images/home-banner/3.jpg';
import sliderImage4 from '../../assets/images/home-banner/4.jpg';
import BackgroundSlideshow from 'react-background-slideshow';
import './style.scss';

export default class PrimaryBanner extends Component {
  render() {

    return (
      <section className="carousel">
				<BackgroundSlideshow images={[sliderImage1, sliderImage2, sliderImage3, sliderImage4 ]} animationDelay={3000} />
				<div className="container">
					<div className="table-wrapper">
						<div className="table-cell">
							<h2>Welcome to Vajra Realties</h2>
							{/* <span className="fa fa-chevron-circle-down" onClick={() => scrollToElement('.portfolio-gallery', { ease: 'linear', duration: 1000 })}></span> */}
						</div>
					</div>
				</div>
			</section>
    );
  }
}