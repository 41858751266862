import React, { Component } from 'react';
import peopleImage1 from '../../assets/images/people/people1.jpg';
import peopleImage2 from '../../assets/images/people/people2.jpg';
import peopleImage3 from '../../assets/images/people/people3.jpg';
import peopleImage4 from '../../assets/images/people/people4.jpg';
import './style.scss';

class OurTeam extends Component {
  render() {
    return (
      <section className="team-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h1>Meet Our Team</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-12 profile">
              <div className="img-box">
                <img src={peopleImage1} className="img-responsive"/>
                <ul className="text-center">
                  <a href="#">
                    <li>
                      <i className="fa fa-facebook"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-twitter"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-linkedin"></i>
                    </li>
                  </a>
                </ul>
              </div>
              <h1>Peter Doe</h1>
              <h2>Founder</h2>
              <p>
                You cannot step into the same river twice.
              </p>
            </div>
            <div className="col-md-4 col-sm-12 profile">
              <div className="img-box">
                <img src={peopleImage2} className="img-responsive"/>
                <ul className="text-center">
                  <a href="#">
                    <li>
                      <i className="fa fa-facebook"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-twitter"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-linkedin"></i>
                    </li>
                  </a>
                </ul>
              </div>
              <h1>Peter Doe</h1>
              <h2>Founder</h2>
              <p>
                You cannot step into the same river twice.
              </p>
            </div>
            <div className="col-md-4 col-sm-12 profile">
              <div className="img-box">
                <img src={peopleImage3} className="img-responsive"/>
                <ul className="text-center">
                  <a href="#">
                    <li>
                      <i className="fa fa-facebook"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-twitter"></i>
                    </li>
                  </a>
                  <a href="#">
                    <li>
                      <i className="fa fa-linkedin"></i>
                    </li>
                  </a>
                </ul>
              </div>
              <h1>Peter Doe</h1>
              <h2>Founder</h2>
              <p>
                You cannot step into the same river twice.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OurTeam;