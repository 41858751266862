import React, { Component } from 'react';
import './style.scss';

class GetInTouch extends Component {
  render() {
    return (
      <section className="get-in-touch">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="wrap-git">
                <div className="customsol">
                  <div className="customsol-content">
                  <div className="git-left">
                    <span className="git-title">Looking for a custom solution ?</span>
                      <p>
                      We hold domain expertise in offering most advanced mobility solutions for real estate
                      </p>
                    </div>
                    <div className="git-right ">
                      <a href="/contact-us" className="btn-git theme-transition">
                        <i className="fa fa-envelope"/> Get In Touch
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default GetInTouch;