import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/PropertyList';
import { getProperties } from '../ducks/PropertyDuck';

const mapStateToProps = (state) => ({
  propertyList: state.propertyDuck.propertyList
});

const mapDispatchToProps = (dispatch) => ({
  getProperties: featured => dispatch(getProperties(featured))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component));