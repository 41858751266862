import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Logo';
import GetInTouch from '../GetInTouch';
import './style.scss';

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <GetInTouch />
        <section className="footer">
          <div className="ct-footer">
            <div className="container">
              <div className="row">
                <div className="col-md-3 col-footer-box">
                  <div className="row-footer">
                    <Logo />
                    <br/>
                    <div>
                    <p>
                      Vajra Realties is a Builders &amp; Developers firm in Mangalore. We also undertake projects from other developers for sales with competitive price which will help customers to buy property within their reach.
                    </p>
                    <br/>
                      <div className="footer-sosmed-icon ">
                        <div className="wrap-out-sosdmed-footer">
                          <div className="wrap-circle-sosmed theme-transition ">
                            <a href="#">
                            <div className="circle-sosmed">
                              <i className="fa fa-instagram"/>
                            </div>
                            </a>
                          </div>
                        </div>
                        <div className="wrap-out-sosdmed-footer">
                          <div className="wrap-circle-sosmed theme-transition ">
                            <a href="#">
                            <div className="circle-sosmed">
                              <i className="fa fa-twitter"/>
                            </div>
                            </a>
                          </div>
                        </div>
                        <div className="wrap-out-sosdmed-footer">
                          <div className="wrap-circle-sosmed theme-transition ">
                            <a href="https://www.facebook.com/Vajra-Realties-249302279299881/" target='_blank'>
                            <div className="circle-sosmed">
                              <i className="fa fa-facebook-f"/>
                            </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-footer-box"></div>
                <div className="col-md-2 col-footer-box">
                  <div className="row-footer">
                    <div className="heading-footer-col">Links</div>
                    <div className="content-footer-col">
                      <ul>
                        <li><a href='/'>Home</a></li>
                        <li><a href='/about-us'>About</a></li>
                        <li><a href='/projects'>Projects</a></li>
                        <li><a href='/contact-us'>Contact</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-footer-box cfb-last">
                  <div className="row-footer">
                    <div className="heading-footer-col">Contact</div>
                    <div className="content-footer-col">
                      <div className="footer-item-contact">
                        <div className="fic-icon">
                          <i className="fa fa-map-marker"/>
                        </div>
                        <div className="fic-contact">
                          Vajra Realties<br/>
                          1st Floor, Essel Tower, Above Axis Bank<br/>
                          Bunts Hostel, Mangalore.<br/>
                        </div>
                      </div>
                      <div className="footer-item-contact">
                        <div className="fic-icon">
                          <i className="fa fa-phone"/>
                        </div>
                        <div className="fic-contact">+91-9741735307, +91-9448912515</div>
                      </div>
                      <div className="footer-item-contact">
                        <div className="fic-icon">
                          <i className="fa fa-envelope-o"/>
                        </div>
                        <div className="fic-contact">sales@vajrarealties.com</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                © {new Date().getFullYear()} Vajra Realties. All rights reserved.
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
