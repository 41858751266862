import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Component from '../components/PropertyDetails';
import { getPropertyDetails } from '../ducks/PropertyDuck';

const mapStateToProps = (state) => ({
  propertyDetails: state.propertyDuck.propertyDetails
});

const mapDispatchToProps = (dispatch) => ({
  getPropertyDetails: (uri) => dispatch(getPropertyDetails(uri))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component));