import React, { Component } from 'react';
import amenitiesBanner from '../../../assets/images/bg-amenities.jpg';
import './style.scss';
import { endpoints } from '../../../config'

class Amenities extends Component {

  render() {
    const { amenities } = this.props
    if(!amenities) {
      return null
    }
    return (
      <div id='amenities' className="container-fluid bg-amenities" style={{backgroundImage: `url(${amenitiesBanner})`}}>
        <div className="amenities">
          <div className="row">
            <div className="col-md-12">
            <div className="section-title">
              <h2><span className="font-theme-color">Amenities</span></h2>
            </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <ul className="amenity-list">
                {
                  amenities && amenities.map(({amenityName, amenityImg}, k) => (
                    <li className="amenity-item" key={k}>
                      <img src={`${endpoints.apiBaseURL}${amenityImg}`} />
                      {amenityName}
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Amenities;