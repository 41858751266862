import React, { Component } from 'react';
import FloorNode from '../FloorNode';
import { RViewer, RViewerTrigger } from 'react-viewerjs'
import { endpoints } from '../../../config'
import notFound from './not-found.jpg'
import './style.scss';

class FloorPlan extends Component {
  render() {
    const { flooring } = this.props
    return (
      <div className="floor-plan-wrapper" id='floor-plan'>
        <div className="container">

          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>Floor <span className="font-theme-color">Plans</span></h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row floor-images">
                {
                  flooring && flooring.map((floor, key) => {
                    let images = []

                    // if(floor.uploadedImagePaths !== null) {
                    //   images = floor.uploadedImagePaths.split(',').map(img => ({
                    //     src: `${endpoints.apiBaseURL}${img.replace('./', '')}`,
                    //     width: 1,
                    //     height: 1
                    //   }))
                    // } else {
                    //   images = [
                    //     {
                    //       src: notFound,
                    //       width: 1,
                    //       height: 1
                    //     }
                    //   ]
                    // }
                    
                    if(floor.uploadedImagePaths !== null) {
                      images = floor.uploadedImagePaths.split(',').map(img => `${endpoints.apiBaseURL}${img.replace('./', '')}`)
                    } else {
                      images = [notFound]
                    }

                    const warraper = (
                      <RViewer imageUrls={images}>
                        <div className='image-gallery'>
                          {images.map((pic, index) => {
                            return (
                              <RViewerTrigger index={index} key={index}>
                                <img src={pic}  />
                              </RViewerTrigger>
                            )
                          })
                          }
                        </div>
                      </RViewer>
                    )
                    
                    return <FloorNode floor={floor} key={key} handler={warraper} />
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FloorPlan;
