import React, { Component } from 'react';
import './style.scss';

export default class SecondaryBanner extends Component {

  render() {
    const { bannerImage, propertyName, rera } = this.props;

    return (
      <section className="container-fluid secondary-banner-wrapper" style={{backgroundImage: `url("${bannerImage}")`}}>
        { propertyName && (
          <div className='container intro'>
            <div>
              <h1>{propertyName}</h1>
              {
                rera && (
                  <div className="rera-number-wrapper">
                    <span className="rera-number">Rera Number : {rera}</span>
                  </div>
                )
              }
            </div>
          </div>
        )}
      </section>
    );
  }
}