import React, { Component } from 'react';
import './style.scss';

class Location extends Component {
  render() {
    const { completeAdress, builderName, iframe } = this.props
    return (
      <div className="location container" id='location'>
        <div className="map-wrapper" dangerouslySetInnerHTML={{__html: iframe}}></div>
        <div className="address-wrapper">
          <div className="section-title">
            <h2><span className="font-theme-color">Address</span></h2>
          </div>
          <div className="address">
            <p>{completeAdress}</p>
            <p>{builderName}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Location;