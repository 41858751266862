import React, { Component } from 'react';
import './style.scss'
import { Modal } from 'react-bootstrap'
import GeneralForm from '../../../containers/GeneralFormContainer'

class InquiryForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }
  render() {
    const { url, propertyName } = this.props
    return (
      <React.Fragment>
        <button className="btn-enquiry theme-transition" onClick={this.handleShow}>
          <i className="fa fa-telegram"></i> Enquire Now
        </button>
        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title>Enquiry Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <GeneralForm body={{url, propertyName}} type='enquiry' handleClose={this.handleClose} />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default InquiryForm;
