import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from './serviceWorker';
import Notifications from 'react-notify-toast';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/main.scss';

import App from './containers/AppContainer';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './screens/Home';
import AboutUsPage from './screens/AboutUs';
import ContactUsPage from './screens/ContactUs';
import PropertyPage from './screens/Properties';
import PropertyDetailsPage from './screens/PropertyDetails';

class Index extends Component {
  render() {
    return (
      <Router>
        <Provider store={store}>
          <App>
            <Header/>
            <Notifications />
            <div className="main-wrapper">
              <Switch>
                <Route path="/" component={HomePage} exact/>
                <Route path="/about-us" component={AboutUsPage} exact/>
                <Route path="/contact-us" component={ContactUsPage} exact/>
                <Route path="/projects" component={PropertyPage} exact/>
                <Route path="/property/:uri" component={PropertyDetailsPage} exact/>
              </Switch>
            </div>
            <Footer/>
          </App>
        </Provider>
      </Router>
    );
  }
}

ReactDOM.render(<Index />, document.getElementById('root'));
serviceWorker.unregister();
