import React, { Component } from 'react';
import AboutImage from '../../assets/images/about-1.jpg';
import './style.scss';

class AboutUs extends Component {
  render() {
    return (
      <section className="about-page">
        <div className="wrap-content-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 none-sm">
                <div className="left-content-area">
                  <div className="img-wrapper">
                    <img src={AboutImage} alt="about images" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="right-content-area">
                  <h4 className="title">Our Company</h4>
                  <p>
                  Vajra Realties is professional sole marketing agency for real estate projects in Mangalore. We can help builders and developers to sell their real estate projects faster.
                  </p>
                  <div className="bottom-content">
                    <br/>
                    <h4 className="title">Why Choose Us</h4>
                    <p>Now a day’s marketing is one of the most important aspects of the real estate market. We know all types of marketing channels very well. Ok, Then why should choose us? Because We have</p>
                    <ul className="about-lists">
                      <li>
                        <i className="icofont icofont-rounded-right"></i>Very experienced sales and executive staff
                      </li>
                      <li>
                        <i className="icofont icofont-rounded-right"></i>Our own flats listing portal for people of Mangalore and rest of the world
                      </li>
                      <li>
                        <i className="icofont icofont-rounded-right"></i>Expert staff in search engine marketing
                      </li>
                      <li>
                        <i className="icofont icofont-rounded-right"></i>Provide very cost effective sole selling service
                      </li>
                      <li>
                        <i className="icofont icofont-rounded-right"></i>In house designing team
                      </li>
                      <li>
                        <i className="icofont icofont-rounded-right"></i>Provide financial services for loan approvals and sanctions
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutUs;