
const apiBaseURL = 'https://backend.vajrarealties.com/'

const property = `${apiBaseURL}api/renderRequestParser.php?action=getProjectDetails&subAction=byURI`;
const properties = `${apiBaseURL}/api/renderRequestParser.php?action=getProperty&subAction=all`
const featured = `${apiBaseURL}/api/renderRequestParser.php?action=getProperty&subAction=featured`
const submitForm = `${apiBaseURL}/api/renderQueries.php?action=postEnquires`

export default {
  properties,
  featured,
  property,
  apiBaseURL,
  submitForm
};
