import React, { Component } from 'react';
import SecondaryBanner from '../../components/SecondaryBanner';
import ContactUs from '../../components/ContactUs';
import DocumentMeta from 'react-document-meta';
import contactUsBanner from '../../assets/images/bg-customsol.jpg';

class ContactUsPage extends Component {
  render() {
    const meta = {
      title: 'Contact Vajra Realties Mangalore',
      description: '',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'vajra realties, properties in mangalore, prop links mangalore'
        }
      }
    }
    return (
      <DocumentMeta {...meta}>
        <SecondaryBanner bannerImage={contactUsBanner}/>
        <ContactUs />
      </DocumentMeta>
    );
  }
}

export default ContactUsPage;