import React, { Component } from 'react';
import './style.scss';

class Services extends Component {
  render() {
    return (
      <section className="services-section">
        <div className="services container-fluid">
          <div className="row">
            <div className="col-md-12 no-padding">
              <div className="cm-left">
                <div className="cm-left-content-layer">
                  <div className="cm-left-content">
                    <div className="section-title">
                      <h2>Prepare for the Future with Our Advisors</h2>
                    </div>
                  </div>
                  <div className="bg-cm"/>
                </div>
              </div>
              <div className="cm-right">
                <div className="cm-right-wrap">
                  <div className="section-title">
                    <h2>Why Us?</h2>
                    <div className="why-list">
                      <div className="wl-box">
                        <div className="wl-box-left">
                          <i className="fa fa-bar-chart"></i>
                        </div>
                        <div className="wl-box-right">
                          <h5>Real Estate Consulting</h5>
                          <p>
                          Vajra Realties is a name synonymous with providing you end-to-end solutions for all your property investments 
                          </p>
                        </div>
                      </div>
                      <div className="wl-box">
                        <div className="wl-box-left">
                          <i className="fa fa-university"></i>
                        </div>
                        <div className="wl-box-right">
                          <h5>NRI Services</h5>
                          <p>
                          Vajra Realties are a one stop solution to all your realty needs. We are not just another property dealing agency
                          </p>
                        </div>
                      </div>
                      <div className="wl-box">
                        <div className="wl-box-left">
                          <i className="fa fa-line-chart"></i>
                        </div>
                        <div className="wl-box-right">
                          <h5>Home Loan &amp; Legal Consultation</h5>
                          <p>
                          Vajra Realties assists you in getting your dream home through home loan services.
                          </p>
                        </div>
                      </div>
                      <div className="wl-box">
                        <div className="wl-box-left">
                          <i className="fa fa-child"></i>
                        </div>
                        <div className="wl-box-right">
                          <h5>After Sales Assistance</h5>
                          <p>
                          Vajra Realties does not stop with just providing you assistance in buying the property you need, 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Services;