import React, { Component } from 'react';
import './style.scss';
import banner from '../../../assets/images/bg-nearby-places.jpg';
import notFound from './not-found.jpg'
import { endpoints } from '../../../config'
class NearByPlaces extends Component {
  render() {
    const { nearByPlaces } = this.props
    return (

    <div id='near-by-places' className="container-fluid bg-near-by-places" style={{backgroundImage: `url(${banner})`}}>
      <div className="near-by-places">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>Near By <span className="font-theme-color">Places</span></h2>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <ul className="place-list row">

                  {
                    nearByPlaces && nearByPlaces.map((data, key) => (
                      <li className="col-xl-4" key={key}>
                        <div className='place-item'>
                          <img alt='' src={data.imgPath ? `${endpoints.apiBaseURL}${data.imgPath}` : notFound} />
                          <div className='content'>
                            {data.placeName ? (<h2>{data.placeName}</h2>) : ''}
                            {data.address ? (<p>{data.address}</p>) : ''}
                            {data.km ? (<p>{data.km}KM</p>) : 'Distance: N/A'}
                          </div>
                        </div>
                      </li>
                    ))
                  }

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    );
  }
}

export default NearByPlaces;
