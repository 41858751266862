import React, { Component } from 'react';
import './style.scss';
import GeneralForm from '../../containers/GeneralFormContainer'

class ContactUs extends Component {
  render() {
    return (
      <section className="contact-page">
        <div className="container">
          <div className="contact-form">
            <div className="row">
              <div className="col-md-12 contact-desc-title">
                <div className="heading-contact-title">We are ready to help you</div>
                <div>
                We look forward to learing more about you <br />Whether you have an immediate need or just exploring new ideas for the future, we're here to listen..
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <div className="heading-footer-col">Our Location</div>
                <div className="map-wrapper">
                  <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3270.662602751877!2d74.84788104367465!3d12.875845233619879!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x83916e4204893738!2sVajra+Realties!5e0!3m2!1sen!2sin!4v1554997489574!5m2!1sen!2sin" width="540" height="450" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                </div>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="heading-footer-col">Send your message</div>
                <GeneralForm type='contact' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactUs;