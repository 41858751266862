import km from 'keymirror';
import API from '../libs/api';
import { endpoints } from '../config';
import { notify } from 'react-notify-toast';

// Define constants
const ACTIONS = km({
  PROPERTIES: null,
  PROPERTY_DETAILS: null
});

// Intial State
const initialState = {
  propertyList: null,
  propertyDetails: null
};

// Define actions
export const getProperties = (featured = false) => {
  return dispatch => {
    (async () => {
      try {
        const api = new API();
        const response = await api.get(featured ? endpoints.featured : endpoints.properties);
        dispatch({
          type: ACTIONS.PROPERTIES,
          payload: response
        });
      }
      catch (err) {
        // notify(err.toString());
      }
    })();
  };
};

export const getPropertyDetails = (uri) => {
  return dispatch => {
    (async () => {
      try {
        const api = new API();
        const response = await api.get(endpoints.property, `&ip=${uri}`);
        dispatch({
          type: ACTIONS.PROPERTY_DETAILS,
          payload: response
        });
      }
      catch (err) {
        // notify(err && err.toString());
      }
    })();
  };
};

export const submitForm = formData => {
  return dispatch => {
    return (async () => {
      try {
        const api = new API();
        await api.post(endpoints.submitForm, formData);
        return Promise.resolve(true)
      }
      catch (err) {
        console.log(err);
        return Promise.reject(false)
      }
    })();
  };
};

//switch
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case ACTIONS.PROPERTIES: {
      return {
        ...state,
        propertyList: payload
      };
    }

    case ACTIONS.PROPERTY_DETAILS: {
      return {
        ...state,
        propertyDetails: payload
      };
    }

    default: {
      return state;
    }
  }
};