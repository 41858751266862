import React from 'react'
import { Link } from 'react-router-dom'
import LogoImage from '../../assets/images/logo.jpeg';

const Logo = () => {
    return (
      <Link className="logo" to="/" style={{display: 'block'}}>
        <img src={LogoImage} alt='Vajra Realties logo' />
      </Link>
    );
  };

  export default Logo