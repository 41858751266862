import axios from 'axios';

export default class API {

  get(url, query = '', params = null) {
    return axios.get(`${url}${query}`, params)
      .then(({data}) => {
        return new Promise((resolve, reject) => {
          if(data.status === undefined) {
            return reject('API Error');
          }
          else if(data.status === 200) {
            return resolve(data.response);
          } else {
            return reject(data.message)
          }
        });
      })
      .catch(function (error) {
        return new Promise((_, reject) => {
          return reject(error);
        });
      });
  }

  post(uri, params = {}) {
    console.log(params)
    return axios.post(uri, params)
      .then(({data}) => {
        return new Promise((resolve, reject) => {
          if(data.status === undefined) {
            return reject('API Error');
          }
          else if(data.status === 200) {
            return resolve(data.response);
          } else {
            return reject(data.message)
          }
        });
      })
      .catch(function (error) {
        return new Promise((_, reject) => {
          return reject(error);
        });
      });
  }

}
